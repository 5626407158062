<template>
  <div class="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
    <header class="mb-auto">
      <div>
        <h3 v-if="!inProgress" class="float-md-start mb-0"></h3>
        <div
          @click="inProgress = false"
          v-if="inProgress"
          class="float-md-end mb-0 close-btn"
          role="button"
        >
          <i class="bi-x-circle"></i>
        </div>
        <audio ref="myaudio" src="@/assets/breathe.mp3" loop></audio>
      </div>
    </header>

    <main class="px-3" v-if="!inProgress">
      <h1>생명의 호흡훈련</h1>
      <p class="lead mt-4">
        <a @click="inProgress = 20" class="btn btn-lg btn-outline-light m-2"
          >20분</a
        >
        <a @click="inProgress = 40" class="btn btn-lg btn-outline-light m-2"
          >40분</a
        >
        <a @click="inProgress = 99999" class="btn btn-lg btn-outline-light m-2"
          >계속</a
        >
      </p>
    </main>
    <main v-else class="d-flex align-items-center justify-content-center">
      <div :key="circleKey" class="circle" :class="{ zoomIn: zoomIn }"></div>
    </main>

    <footer class="mt-auto text-white-50">
      <p v-if="!inProgress">
        <a href="#" class="text-white"
          >박종형</a
        >
      </p>
    </footer>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      inProgress: false,
      zoomIn: false,
      circleKey: 0,
    };
  },
  watch: {
    inProgress() {
      if (this.inProgress) {
        this.circleKey += 1;
        this.$refs.myaudio.load();
        this.$refs.myaudio.curruntTime = 0;
        this.$refs.myaudio.volume = 1;
        this.$refs.myaudio.play();
        this.breathe(this.inProgress);
      } else {
        this.circleKey += 1;
        let audio = this.$refs.myaudio;
        let actualVolumeFadeOut = audio.volume;
        let fadeOutInterval = setInterval(function () {
          actualVolumeFadeOut = (parseFloat(actualVolumeFadeOut) - 0.1).toFixed(
            1
          );
          if (actualVolumeFadeOut >= 0) {
            audio.volume = actualVolumeFadeOut;
          } else {
            audio.pause();
            clearInterval(fadeOutInterval);
          }
        }, 100);
      }
    },
  },
  methods: {
    async breathe(time) {
      let currentKey = this.circleKey;
      this.zoomIn = false;
      let timeInMs = time * 60000;
      let startTime = Date.now();
      while (this.inProgress && currentKey == this.circleKey) {
        await this.timeout(5500);
        if (Date.now() - startTime > timeInMs) break;
        if (currentKey != this.circleKey) break;
        this.zoomIn = true;
        await this.timeout(5500);
        if (currentKey != this.circleKey) break;
        this.zoomIn = false;
      }
      this.inProgress = false;
    },
    timeout(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
};
</script>

<style>
.circle {
  border-radius: 50%;
  background-color: deepskyblue;
  opacity: 0.3;
  position: absolute;
  transition: all 4.9s ease-in-out;
}
@media (min-width: 320px) {
  .circle {
    width: 20%;
    padding-bottom: 20%;
  }
}
@media (min-width: 961px) {
  .circle {
    width: 10%;
    padding-bottom: 10%;
  }
}

.zoomIn {
  transform: scale(4);
  opacity: 1;
}

.close-btn {
  opacity: 0.6;
}

.close-btn:hover {
  opacity: 1;
}

@import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "@/css/bt.css";
@import "@/css/cover.css";
</style>
